<template>
  <div>
    <MyHeader />
    <main id="content">
      <div class="container mt-5">
        <BookingInfo style="display:none;" />
        <div class="row">
          <section class="col-lg-8 col-md-7 col-sm-6 cancellation-form"
                   v-if="finishedCancellation">
            <div>
              <h3>Your reservation has been cancelled</h3>
              <p v-if="getBooking && getBooking.status != 2">
                Your request has been submitted, in a few hours Home Be Like team will have a answer
              </p>
              <hr>
              <router-link :to="{ name: 'user-trips' }">
                <i class="icon-cheveron-left" /> Back to my profile
              </router-link>
            </div>
          </section>
          <section class="col-lg-8 col-md-7 col-sm-6 cancellation-form"
                   v-else>
            <h3>Do you want to cancel your reservation?</h3>
            <hr>
            <div class="row mt-5"
                 v-if="getBooking && getBooking.property && getBooking.status != 2">
              <div class="col-lg-11">
                <h4>Cancellation Policy</h4>
                <p v-html="getBooking.property.home_policies" />
                <p>
                  Depending on the cancellation policy applied to the property and the dates on which you are making your cancellation, charges may apply.
                </p>
                <p>
                  Our concierge will be contacting you with the details of the charges applied and the total return of your reservation.
                </p>
              </div>
            </div>
            <div class="row mt-5"
                 v-if="getBooking && getBooking.status && getBooking.status == 2">
              <div class="col-lg-11">
                <h4>Cancellation Policy</h4>
                <p>By canceling your request, the credit card authorization hold will be canceled in your bank account or it will be shown as a refund. Depending on the processing times of your bank, the cancellation may take up to 7 working days to be reflected in your account.</p>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-lg-12">
                <form @submit.prevent="cancellationForm">
                  <div class="form-group">
                    <textarea v-model="cancelattion.reason"
                              v-validate="getBooking && getBooking.status != '2' ? 'required' : ''"
                              name="reason"
                              rows="3"
                              class="form-control"
                              placeholder="Why are you cancelling your reservation?" />
                  </div>
                  <span class="text-danger small mb-3 d-block fix-margin"
                        v-if="errors.has('reason')">{{ errors.first('reason') }}</span>
                  <button :disabled="getBooking && getBooking.status != '2' && !cancelattion.reason"
                          type="button"
                          class="btn btn-primary btn-block btn-lg"
                          @click="cancelBooking">
                    Cancel
                  </button>
                </form>
              </div>
            </div>
          </section>
          <aside class="col-lg-4 col-md-5 col-sm-6"
                 v-if="getBooking">
            <ul class="article-to-modify">
              <li>
                <img :src="getBooking.property.images[0].thumbnail"
                     :alt="getBooking.property.name"
                     class="thumb">
                <div class="info">
                  <h2>{{ getBooking.property.name }}</h2>
                  <address class="location">
                    {{ getBooking.property.state.name }}, {{ getBooking.property.country.name }}
                  </address>
                  <hr>
                  <label class="calenBook">
                    <i class="icon-calendar" />
                    <div><small>Check In</small> {{ getBooking.check_in|beatyDate }}</div>
                    <i class="icon-arrow-right" />
                    <div><small>Check Out</small> {{ getBooking.check_out|beatyDate }}</div>
                  </label>
                  <label class="calenBook">
                    <i class="icon-users" />
                    <div><small>Travelers</small> {{ getBooking.travelers }}</div>
                  </label>
                </div>
              </li>
            </ul>
          </aside>
        </div>
      </div>
    </main>
    <MyFooter />
  </div>
</template>

<script>
import BookingInfo from '../../components/users/BookingInfo.vue';

export default {
  name: 'UserTripCancellation',
  components: {
    BookingInfo,
  },
  data() {
    return {
      finishedCancellation: null,
      cancelattion: {
        reason: null,
      }
    };
  },
  computed: {
    getBooking() {
      return this.$store.getters.getBookingTrip;
    },
  },
  methods: {
    cancelBooking() {
      const formData = new FormData();
      formData.append('cancellation_reason', this.cancelattion.reason);
      this.$axios.post(`/v2/bookings/${this.$route.params.id}/cancel`, formData).then((response) => {
        this.finishedCancellation = response.data.data;
        Promise.resolve();
      }).catch((error) => {
        this.error = error.response.data.error;
      });
    }
  },
};
</script>

<style lang="scss" scoped>

</style>
